.sedi{
  padding: 120px 0 180px 0;
  .intro-area{
		text-align: center;
		margin-bottom: 40px;
		span{
			display: block;
			font-size: 14px;
			@include mainfontbold()
			@include respond-to(md) {
				display: none;
			}
		}
		h1{
			@include secondfontbold();
			color: $blue;
			font-size: 45px;
			line-height: 1.1;
			margin-bottom: 20px;
			@include respond-to(sm) {
				font-size: 60px;
			}
			@include respond-to(md) {
				font-size: 80px;
			}
			strong{
				color: $blue;
			}
		}
		h2{
			display: block;
			font-size: 18px;
			@include mainfontbold();
			width: 70%;
			margin: 0 auto;
			display: block;
			color: $blue;
			text-transform: uppercase;
		}
	}
  .single-sede{
    margin: 40px 0 30px;
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0;
    cursor:pointer;
    &.green{
      background: $green;
    }
    &.lilla{
      background: $lilla;
    }
    &.yellow{
      background: $yellow;
    }
    text-align: center;
    padding: 40px 15px 30px;
    color: $white;
    img{
      width: 65%;
      margin: 20px auto 30px;
      display: block;
    }
    h6{
      font-weight: 300;
      font-size: 32px;
      margin-bottom: 20px;
      span{
        display: block;
        font-size: 20px;
      }
    }
    a{
      color: $white;
      display:block;

      &.tel{
        @include mainfontbold();
        margin-top: 10px;
        display: inline-block;
      }
      &.mail{
        font-weight: bold;
      }
    }

    &:hover{
      @include respond-to(md){
        transition: box-shadow .3s .2s ease-in-out;
        transform: scale(1.005);
        transition: transform 0.3s  ease-in-out;
        box-shadow: 0 2px 4px 0 rgba(60,64,67,0.08), 0 6px 10px 4px rgba(60,64,67,0.15);
      }
    }
  }
}
