//------------------------------------------------------------ typography

// FONT FAMILY
$futura: "futura-pt", sans-serif;
$fontello: 'fontello';
$fontawesome: 'FontAwesome';
$poppins: 'Poppins', sans-serif;
$mostra: 'mostra-nuova', sans-serif;
$modesto: 'modesto-condensed', serif;
$abril: 'Abril Fatface', cursive;

// FONT SIZE
$font-size-base: 15px;

// FONT WEIGHT
$w-black: 900;
$bold: 700;
$s-bold: 600;
$medium: 500;
$book: 400;
$regular: 300;
$light: 200;

@mixin abril() {
  font-family: $abril;
  font-weight: 400;
}

@mixin mostra($weight) {
  font-family: $mostra;
  $weights: (
    light: 300,
    regular: 400,
    bold: 700
  );

  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }

  font-weight: $output;
}

@mixin modesto($weight) {
  font-family: $modesto;
  $weights: (
    light: 300,
    bold: 700
  );

  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }

  font-weight: $output;
}

@mixin fontello() {
	font-family: $fontello;
}
@mixin fontawesome() {
	font-family: $fontawesome;
}
@mixin futurabold() {
  font-family:$futura;
  font-weight: $bold;
}
@mixin futurabook() {
  font-family: $futura;
  font-weight: $book;
}

@mixin mainfont() {
	@include mostra(400);
}
@mixin mainfontbold() {
	@include mostra(700);
}
@mixin secondfont() {
	@include modesto(300);
}
@mixin secondfontbold() {
	@include modesto(700);
}

// BASE TYPO
@mixin basetypo() {
  font-size: $font-size-base;
  line-height: 1.6;
  @include mainfont();
  @include respond-to(sm) {
    font-size: $font-size-base + 1px;
  }
  @include respond-to(md) {
    font-size: $font-size-base + 2px;
  }
  @include respond-to(lg) {
    font-size: $font-size-base + 3px;
  }
}
@mixin blocktxth1() {
  font-size: 32px;
  line-height: 1.4;
  @include mainfontbold();
  @include respond-to(sm) {
    font-size: 36px;
  }
  @include respond-to(md) {
    font-size: 50px;
  }
  @include respond-to(lg) {
    font-size: 64px;
  }
}
@mixin blocktxth2() {
  font-size: 24px;
  line-height: 1.4;
  @include mainfontbold();
  @include respond-to(sm) {
    font-size: 26px;
  }
  @include respond-to(md) {
    font-size: 30px;
  }
  @include respond-to(lg) {
    font-size: 34px;
  }
}
@mixin blocktxth3() {
  font-size: 20px;
  line-height: 1.4;
  @include mainfontbold();
  @include respond-to(sm) {
    font-size: 24px;
  }
  @include respond-to(md) {
    font-size: 27px;
  }
  @include respond-to(lg) {
    font-size: 30px;
  }
}
@mixin blocktxth4() {
  font-size: 18px;
  line-height: 1.4;
  @include mainfontbold();
  @include respond-to(sm) {
    font-size: 21px;
  }
  @include respond-to(md) {
    font-size: 23px;
  }
  @include respond-to(lg) {
    font-size: 28px;
  }
}
@mixin blocktxth5() {
  font-size: 16px;
  line-height: 1.4;
  @include mainfontbold();
  @include respond-to(sm) {
    font-size: 18px;
  }
  @include respond-to(md) {
    font-size: 22px;
  }
  @include respond-to(lg) {
    font-size: 25px;
  }
}
@mixin blocktxth6() {
  font-size: 14px;
  line-height: 1.4;
  @include mainfontbold();
  @include respond-to(sm) {
    font-size: 16px;
  }
  @include respond-to(md) {
    font-size: 18px;
  }
  @include respond-to(lg) {
    font-size: 20px;
  }
}
@mixin blocktxtp() {
  @include basetypo();
  line-height: 1.8;
}

// CUSTOM TYPO
@mixin blockquote() {
  font-size: 28px;
  line-height: 1.4;
  @include mainfontbold();
  @include respond-to(sm) {
    font-size: 32px;
  }
  @include respond-to(md) {
    font-size: 38px;
  }
  @include respond-to(lg) {
    font-size: 42px;
  }
}
@mixin blockquote2() {
  font-size: 15px;
  line-height: 1.4;
  @include mainfontbold();
  @include respond-to(sm) {
    font-size: 18px;
  }
  @include respond-to(md) {
    font-size: 21px;
  }
  @include respond-to(lg) {
    font-size: 24px;
  }
}

//------------------------------------------------------------ end typography
