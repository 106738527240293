//------------------------------------------------------------base styles
html {
	height: 100%;
	// overflow: auto;
	// margin: 0;

}

body {
	position: relative;
	height: 100%;
	font-size: $font_size_base;
	color: $color_text_base;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $body_bg;
	@include basetypo();
	&.navopen{
		overflow-y: hidden;
	}
}

.wrapper {
	position: relative;
	min-height: 100%;
	height: auto !important;
	overflow: hidden;
}

.base {
	position: relative;
	padding: $height_header 0 $height_footer;
}

a {
	text-decoration: none;
	color: $color_link_base;
	&:hover,
	&:focus {
		text-decoration: none;
	}
	&:active,
	&:focus{
		outline: none;
	}
}

button{
  &:hover, &:active, &:focus{
    outline: none;
  }
}

.btn-base{@include btn-base();}

.elements_list {
	padding: 3rem;
}

//------------------------------------------------------------base styles###
