.footer {
	padding: 60px 0;
	color: $white;
	background: $blue;
	text-align: center;
	transform: scale(1);
	font-size: 14px;
	.legal-footer{
		display: none;
		margin-top: 20px;
		@include respond-to(md){
			display: block;
		}
	}
	.legal-footer-mobile{
		display: block;
		margin-top: 20px;
		@include respond-to(md){
			display: none;
		}
	}
	&.navopen{
		transform: scale(0);
	}
}
