.main-modale{
  display:none;
  &.active{
    display:block;
    height: 100%;
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index: 9999;
    overflow-y: hidden;
    &.animated{
      @include respond-to(sm){
        .pane-modale-slider{
          .pane-modale{
            .right-side{
              animation: tobottom .8s linear;
            }
            .left-side{
              animation: totop .8s linear;
            }
          }
          .slick-arrow{
            animation: tobottom .8s linear;
          }
        }
        .close-modale,.prev-arrow,.next-arrow,.navigation{
          animation: tobottom .8s linear;

        }
      }
    }
  }
  .pane-modale-slider{
    .pane-modale{
      &.speciale_mod{
        .right-side{
          background-color: $green;
          transition: background-color 440ms linear;
          transition-delay: 60ms;

          .speciale-txt{
            transform: scale(1);
            @include respond-to(sm){
              animation: fromtop .5s linear;
            }
          }
        }
      }
      &.slideIn{
        .right-side{
          @include respond-to(sm){
             transition: background-color 500ms linear;

          }
          .speciale-txt{
            @include respond-to(sm){
              animation: frombottom .5s linear;
            }
          }
        }
      }
      &.slideOut, &.slideOutRev{
        .right-side{
          @include respond-to(sm){
           transition: background-color 500ms linear;
           background-color: $blue;
          }
          .speciale-txt{
            @include respond-to(sm){
              animation: totop .5s linear;
            }
          }
        }
      }

      .left-side{
        height: 40vh;
        width: 100vw;
        background: $white;
        position: relative;
        @include respond-to(sm){
        height: 100vh;
        width: 50vw;
         animation: fromtop .8s linear;
        }
        .immagine{
          position: absolute;
          top:0;
          bottom:0;
          left:0;
          right: 0;
          margin:0 auto;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @include respond-to(sm){
            padding: 10%;
          }
          img{
            width: 50%;
           margin-left: 24%;
            @include respond-to(sm){

              width:90%;
             margin-left: 0;

            }
          }
        }
      }
      .right-side{
        padding: 10px;
        background-color: $blue;
        // transition: background-color 440ms linear;
        // transition-delay: 60ms;
        height: 60vh;
        color: $white;
        text-align: center;
        justify-content: center;
        @include respond-to(sm){
          height: 100vh;
          padding: 60px;
          animation: frombottom .8s linear;
        }
        .speciale-txt{
          transform: scale(0);
          @include respond-to(sm){
            animation: frombottom .8s linear;
            // color:$blue;
          }
        }
        .text-inside{
          position: absolute;
          top: 50px;
          bottom:0;
          left: 5%;
          right: 5%;
          @include respond-to(sm){
            top: calc(50vh - 300px) ;
          }
          .number{
            display:block;
            margin-bottom:5vh;
            margin-top:2vh;
            @media (min-height: 769px) and (max-height: 801px){
              margin-bottom: 20vh;

            }
          }
          .title{
            h2{
              font-size: 30px;
              font-family: $modesto;
              @include respond-to(sm){
                font-size: 80px;
              }
            }
          }
          .descrizione{
            p{
              font-size: 16px;
              @include respond-to(sm){
                font-size: 20px;
                margin: 0 50px 0 50px;
              }
            }
          }
          hr{
            margin: 10px auto;
            background: rgba($white, 0.6);
            width:80%;
            @include respond-to(sm){
              margin: 60px auto;
            }
          }
          .segni{
            p{
              font-weight: 700;
            }
          }
        }
      }
    }
    .slick-slide{
      &.slidePrevRev{
        .left-side{
          .immagine{
            @include respond-to(sm){
              animation: frombottom .5s linear;
            }
          }
        }
        .right-side{
          .text-inside,.speciale-txt{
            @include respond-to(sm){
            animation: fromtop .5s linear;
            }
          }
        }
      }
    }
  }
  .navigation{
    position:absolute;
    width:50vw;
    height: 60px;
    right: 0;
    overflow:visible;
    z-index:9999999;
    background: $blue;
    bottom: 42vh;
    width:100vw;
    display:flex;
    align-items: center;
    justify-content: space-between;
    // transition: background 400ms linear;
    // transition-delay: 40ms;
    &.speciale-nav{
      @include respond-to(sm){
        transition: background 460ms linear;

      }
      background: $green;
    }
    @include respond-to(sm){
      animation: frombottom .8s linear;
      bottom:0;
      width:50vw;
      height: 120px;


      &.slideOut{
        transition: background 500ms linear;
      }
      &.slideOutRev{
        background: $blue;
        transition: background 460ms linear;
      }
    }
  }
  .close-modale{
    position:relative;
    // right:calc(50% - 20px) ;
    // bottom:10vh;
    // top: 30%;
    z-index:9999999;
    cursor: pointer;
    width: 30px;
    height: 30px;

    @include respond-to(sm){
      // right:calc(26vw - 20px);
      // bottom:10vh;
      // top: 30%;
      animation: frombottom .8s linear;
    }
    &:after{
      content:"";
      position:absolute;
      bottom:18px;
      display: block;
      width: 30px;
      height: 2px;
      background: $white;
      transform: rotate(-45deg);
    }
    &:before{
      content:"";
      position:absolute;
      bottom:18px;
      display: block;
      width: 30px;
      height: 2px;
      background: $white;
      transform: rotate(45deg);
    }
  }
  .prev-arrow{
    // display:inline-block;
    // position:absolute;
      position:relative;
    // top:calc(30% + 20px);
    // left:calc(10% + 10px );
    z-index:9;
    font-size: 0;
    height: 80px;
    margin-top: 60px;
    margin-left: 10%;
    cursor:pointer;
    // right:calc(84.5%  - 80px);
    @include respond-to(sm){
      // left:20%;
      transition: transform .2s ;
      // bottom:8%;
      // top:calc(30% + 20px);
      animation: frombottom .8s linear;
      &:hover{
        transform: translateX(-10px);
        transition: transform .2s ;
      }
    }
    &:before{
      position:absolute;
      top:7px;
      left:0;
      content:"";
      display:block;
      width:80px;
      height:2px;
      background: $white;
    }
    &:after{
      content:"";
      position:absolute;
      left: 0;
      top:0;
      width:15px;
      height: 15px;
      display:block;
      border-top: 2px solid $white;
      border-left: 2px solid $white;
      transform: rotate(-45deg);
    }
  }
  .next-arrow{
    // display:inline-block;
    position:relative;
    // top:calc(30% + 20px);
    z-index:9;
    font-size: 0;
    height: 80px;
    margin-top: 60px;
    margin-right: 10%;
    // right: calc(10% + 20px );
    cursor:pointer;
    // right: calc(32.5vw - 80px);
    @include respond-to(sm){
      transition: transform .2s ;
      // bottom:8%;
      animation: frombottom .8s linear;
      &:hover{
        transform: translateX(10px);
        transition: transform .2s ;
      }
    }
    &:before{
      content:"";
      position:absolute;
      top:7px;
      right:0;
      display:block;
      width:80px;
      height:2px;
      background: $white;
    }
    &:after{
      content:"";
      position:absolute;
      right: 0;
      top:0;
      width:15px;
      height: 15px;
      display:block;
      border-bottom: 2px solid $white;
      border-right: 2px solid $white;
      transform: rotate(-45deg);
    }
  }
  .slideIn{
    .right-side{
      @include respond-to(sm){
      animation: frombottom 0.5s linear;
      }
      .text-inside{
        @include respond-to(sm){
        animation: frombottom 0.5s linear;
        }
      }
    }
    .left-side{
      @include respond-to(sm){
        animation: fromtop 0.5s linear;
      }
      .immagine{
        @include respond-to(sm){
          animation: fromtop 0.5s linear;
        }
      }
    }
    // &.slidePrevRev{
    //   .left-side{
    //     animation: frombottom .8s linear;
    //   }
    // }
  }
  .slideOut{
    .speciale_mod{
      .right-side{
        .speciale-txt{
          @include respond-to(sm){
            animation: totop .5s linear;
          }
        }
      }
    }
    .right-side{
      .text-inside{
        @include respond-to(sm){
          animation: totop .5s linear;
        }
      }
      .speciale-txt{
        @include respond-to(sm){
          animation: totop .5s linear;
        }
      }
    }
    .left-side{
      @include respond-to(sm){
        animation: tobottom .5s linear;
      }
      .immagine{
        @include respond-to(sm){
          animation: tobottom .5s linear;
        }
      }
    }
  }
  .slideOutRev{
    .speciale_mod{
      .right-side{
        .speciale-txt{
          @include respond-to(sm){
            animation: tobottom .5s linear;
          }
        }
      }
    }
    .right-side{
      .text-inside{
        @include respond-to(sm){
          animation: tobottom .5s linear;
        }
      }
      .speciale-txt{
        @include respond-to(sm){
          animation: tobottom .5s linear;
        }
      }
    }
    .left-side{
      @include respond-to(sm){
        animation: totop .5s linear;
      }
      .immagine{
        @include respond-to(sm){
          animation: totop .5s linear;
        }
      }
    }
  }
  // .navigation{
  // width:50%;
  // background: $blue;
  // }
}


@keyframes fromtop {
  0% {
    transform: translateY(-1600px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes frombottom {
  0% {
    transform: translateY(1600px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes totop {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1600px);
  }
}
@keyframes tobottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(1600px);
  }
}
