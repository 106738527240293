.carousel{
  .slick-slider{
    &.reset-margin_mod{
      margin: 0 -15px 0;
    }
    .slick-slide{
      margin: 0 15px;
    }
  }
}
