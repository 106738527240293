.edoardo{
	padding: 120px 0 90px;
	position: relative;
	z-index: 3;
	margin-bottom: 230px;
	&:before{
		content: '';
		width: 100%;
		position: absolute;
		top: 150px;
		bottom: 70px;
		background: $blue;
		@include respond-to(md){
			top: 180px;
			bottom: 180px;
		}
	}
	.edoardo-img{
		img{
			display: block;
			width: 100%;
		}
	}
	.edoardo-txt{
		padding: 30px 0 0 8px;

		@include respond-to(md){
			padding: 0 0 0 60px;
		}
		h4{
			color: $yellow;
			font-size: 38px;
			@include secondfontbold();
			margin-bottom: 30px;
		}
		h5{
			@include secondfontbold();
			font-size: 48px;
			color: $white;
			line-height: 1.1;
			strong{
				color: $green;
			}
		}
		a{
			font-size: 12px;
			color: $white;
			letter-spacing: 5px;
			@include mainfont();
			font-weight: 300;
			text-transform: uppercase;
			float: right;
			margin-top: 40px;
			margin-right: 40px;
			span{
				width: 98px;
				height: 20px;
				position: relative;
    		display: block;
				transform: translateX(60px);
				margin-top: 5px;
				transition: transform 0.25s;
				&:before{
					content: '';
					position: absolute;
					top: 10px;
					height: 1px;
					background: $white;
					right: 0;
					left: 0;
					width: 100%;
				}
				&:after{
					content: '';
					position: absolute;
					width: 10px;
					height: 10px;
					border-right: 1px solid $white;
					border-bottom: 1px solid $white;
					transform: rotate(-45deg);
					right: 0;
					top: 5px;
				}
			}
			&:hover{
				span{
					transform: translateX(70px);
					transition: transform 0.25s;
				}
			}
		}
	}
}
