//------------------------------------------------------------layout
$hamb-w: 35px;
$hamb-h: 20px;
$hamb-l-h:2px;
$hamb-l-w: 35px;

header{
	// GENERIC STYLES
	&.main-header{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: $zindex-max - 1;
		background: $blue;
		padding: 10px 0;
		// LIST STYLE
		.top-menu-content{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding: 0 5vw;
			@include respond-to(md){
				padding: 0 50px;
			}
			.logo-area{
				width: 150px;
				.logo{

					img{
						width: 110px;
						vertical-align: text-top;
					}
				}
			}
			.menu-area{
				// width: calc(100% - 150px);
				height: 20px;
				position:absolute;
				right:8vw;
				top:26%;
				// bottom: calc(50% - 20px);
				.nav-menu{
					ul{
						display: none;
						@include respond-to(md){
							display: inline-block;
						}
					}
				}
				.social-menu{
					display: none;
					margin-left: 35px;
					position: relative;
					li{
						margin: 0 5px;
					}
					&:before{
						content: '';
						position: absolute;
						left: -18px;
						width: 1px;
						height: 30px;
						background: $white;
					}
					@include respond-to(md){
						display:inline-block;
					}
				}
			}
		}
		&.v_nav-list{
			.nav-menu{
				text-align: right;
				vertical-align: text-top;
				ul{
					margin: 0;
					padding: 0;
					display: none;
					@include respond-to(md) {
						display: block;
					}
					li{
						display: inline-block;
						color: $white;
						margin: 0 15px;
						@include mainfontbold();
						font-size: 16px;
						text-transform: uppercase;
						&:last-child{
							margin: 0 0 0 10px;
						}
						a{
							color: inherit;
							font-size: inherit;
							transition: color .2s;
							&:hover{
								color: rgba($white, 0.2);
							}
							&.selected{
								color: rgba($white, 0.2);
							}
						}

					}
				}
			}
			&.navopen {
				display: block;
				@include basetransition($timing-fast);
				@include respond-to(md) {
					display: none;
				}
			}
			.hamburger-cont{
				display: inline-block;
				@include respond-to(md) {
					display: none;
				}
			}
		}
		// HAMBURGER STYLE
		&.v_hamburger{
			.hamburger-cont{
				display: inline-block;
			}
			.nav-menu{
				text-align: right;
				ul{
					display: none;
				}
			}
		}
		&.modaleopen{
			z-index: 9999;
		}
	}
	.hamburger-cont{
  	position: relative;
		width: $hamb-w;
		height: $hamb-h;
		z-index: $zindex-max;
		&.navopen{
			.hamburger{
				.middle-line{
					background: transparent;
					opacity: 0;
					@include basetransition($timing-fast);
				}
				&:after{
					transform: rotate(-45deg);
					top: 10px !important;
					@include basetransition($timing-fast);
				}
				&:before{
					transform: rotate(45deg);
					top: 10px !important;
					@include basetransition($timing-fast);
				}
			}
		}
		.hamburger{
			position: relative;
			background: transparent;
			border: 0;
	    width: 100%;
  		height: 100%;
			&.two-lines_mod{
				.middle-line{
					display: none;
				}
				&:after{
					top: 10px;
			    right: 0;
				}
				&:before{
					bottom: 0px;
			    right: 0;
				}
			}
			&.three-lines_mod{
				.middle-line{
					right: 0;
				}
				&:after{
					top: 3px;
			    right: 0;
				}
				&:before{
					bottom: 2px;
			    right: 0;
				}
			}
			.middle-line{
				height: 1px;
				width: $hamb-l-w ;
				position: absolute;
				background: $white;
				@include basetransition($timing-fast);
			}
			&:after{
				content: '';
				height: $hamb-l-h;
				width: $hamb-l-w;
				background: $white;
				position: absolute;
		    @include basetransition($timing-fast);
			}
			&:before{
				content: '';
				height: $hamb-l-h;
				width: $hamb-l-w;
				background: $white;
				position: absolute;
		    @include basetransition($timing-fast);
			}
		}
	}
}

// MOBILE MENU STYLE AND VARIATIONS
.mobile-menu{
	position: fixed;
	z-index: 9999;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: $blue;
	color: $white;
	display: none;
	font-size: 40px;
	font-weight: 700;
	font-family: $modesto;
	@include basetransition($timing-fast);
	@include respond-to(xxs){
			font-size: 45px;
	}
	.row{
 		min-height: 100vh;
	}
	&.center_mod{
		ul{
			text-align: center;
		}
	}
	&.left_mod{
		ul{
			text-align: left;
		}
	}
	&.right_mod{
		ul{
			text-align: right;
		}
	}
	ul{
		padding: 0;
		li{
			display: block;
			color: $white;
			a{
				color: inherit;
				font-size: inherit;
			}
		}
	}
	&.v_nav-list{
		&.navopen {
			display: block;
			@include respond-to(md) {
				display: none;
			}
		}
	}
	&.v_hamburger{
		&.navopen {
			display: block;
		}
	}
	&.navopen {
		@include basetransition($timing-fast);
	}
	.social-menu{
		li{
			display:inline-block;
			margin: 0 10px 0;
			.fab{
				font-size: 26px;
				font-weight: normal;
			}
		}
	}
}

//------------------------------------------------------------layout###
