.pane-speciale{
	padding: 120px 0 120px;
	position: relative;
	background: rgba($grey, 0.3);
	.intro-area{
		text-align: center;
		margin-bottom: 40px;
		span{
			display: block;
			font-size: 14px;

			@include mainfontbold()
			@include respond-to(md) {
				display: none;
			}
		}
		h1{
			@include secondfontbold();
			color: $blue;
			font-size: 45px;
			line-height: 1.1;
			margin-bottom: 20px;
			@include respond-to(sm) {
				font-size: 60px;
			}
			@include respond-to(md) {
				font-size: 80px;
			}
			strong{
				color: $green;
			}
		}
		h2{
			display: block;
			font-size: 18px;
			@include mainfontbold();
			width: 70%;
			margin: 0 auto;
			display: block;
			color: $blue;
			text-transform: uppercase;
		}
	}
	.single-pane-speciale{
		cursor: pointer;
		background: $white;
		padding: 0;
		// margin-bottom: 30px;
		margin: 0 8px 30px;
		// // min-height: 448px;
	  border: 8px solid $green;
		border-radius: 3px;
		transform: scale(1);
		transition: box-shadow 0.2s ease-in-out;
		box-shadow: 0;
		&:hover{
			box-shadow: 0 2px 4px 0 rgba(60,64,67,0.08), 0 6px 10px 4px rgba(60,64,67,0.15);
			transition: box-shadow 0.1s ease-in-out;
			transform: scale(1.005);
			transition: transform 0.2s ease-in-out;

		}

		@include respond-to(md){
			padding: 25px 30px;
			margin: 2px 15px 30px;
		}
		span{
			text-align: center;
			color: $blue;
			display: block;
			margin: 5px 0;
			font-weight: 600;
			padding-top: 10px;
		}
		img{
			width: 80%;
			display: block;
			margin: 10px auto 20px;
		}
		h2{
			font-size: 28px;
			@include secondfontbold();
			text-align: center;
			min-height: 119px;
			padding:  0 20px 0;
			@include respond-to(md){
				font-size: 35px;
			}
		}
	}
	.slick-slide{
		div{
			div{
				vertical-align: top;
			}
		}
	}
}
