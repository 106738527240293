.speciale-main-modale{
  display: none;
  &.active{
    display:block;
    height: 100%;
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index: 9999;
    overflow-y: hidden;
    &.animated{
      @include respond-to(sm){
        .speciale-modale-slider{
          .speciale-modale{
            .right-side{
              animation: tobottom .8s linear;
            }
            .left-side{
              animation: totop .8s linear;
            }
          }
          .slick-arrow{
            animation: tobottom .8s linear;
          }
        }
        .close-modale-speciale,.next-arrow-speciale,.prev-arrow-speciale,.navigation{
          animation: tobottom .8s linear;
        }

      }
    }
  }
  .speciale-modale-slider{
    .speciale-modale{
      &.speciale_mod{
        .right-side{
          background: $green;
          .speciale{
            transform: scale(1);
          }
        }
      }

      .left-side{
        height: 40vh;
        width: 100vw;
        background: $white;
        position: relative;
        @include respond-to(sm){
        height: 100vh;
        width: 50vw;
         animation: fromtop .8s linear;
        }
        .immagine{
          position: absolute;
          top:0;
          bottom:0;
          left:0;
          right: 0;
          margin:0 auto;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @include respond-to(sm){
            padding: 10%;
          }
          img{
            width: 50%;
           margin-left: 24%;
            @include respond-to(sm){

              width:90%;
             margin-left: 0;

            }
          }
        }
      }
      .right-side{
        padding: 10px;
        background-color: $blue;
        height: 60vh;
        color: $white;
        text-align: center;
        justify-content: center;
        @include respond-to(sm){
          height: 100vh;
          padding: 60px;
          animation: frombottom .8s linear;
        }
        .speciale{
          transform: scale(0);
        }
        .text-inside{
          position: absolute;
          top: 50px;
          bottom:0;
          left: 5%;
          right: 5%;
          @include respond-to(sm){
            top: calc(50vh - 300px) ;

          }
          .number{
            display:block;
            margin-bottom:5vh;
            margin-top:2vh;
          }
          .title{
            h2{
              font-size: 30px;
              font-family: $modesto;
              @include respond-to(sm){
                font-size: 80px;
              }
            }
          }
          .descrizione{
            p{
              font-size: 16px;
              @include respond-to(sm){
                font-size: 20px;
                  margin: 0 50px 0 50px;
              }
            }
          }
          hr{
            margin: 10px auto;
            background: rgba($white, 0.6);
            width:80%;
            @include respond-to(sm){
              margin: 60px auto;
            }
          }
          .segni{
            p{
              font-weight: 700;
            }
          }
        }
      }
    }
    .slick-slide{
      &.slidePrevRev{
        .left-side{
          .immagine{
            @include respond-to(sm){
              animation: frombottom .5s linear;
            }
          }
        }
        .right-side{
          .text-inside, .speciale{
            @include respond-to(sm){
            animation: fromtop .5s linear;
            }
          }
        }
      }
    }
  }
  .navigation{
    position:absolute;
    bottom: 42vh;
    width:100vw;
    height: 60px;
    right: 0;
    overflow:visible;
    z-index:9999999;
    background: $green;
    @include respond-to(sm){
      animation: frombottom .8s linear;
      bottom:0;
      width:50vw;
      height: 120px;
    }
  }
  .close-modale-speciale{
    position:absolute;
    top: 30%;
    right:calc(50% - 20px);
    z-index:9999999;
    bottom:10vh;
    cursor: pointer;
    width:40px;
    height: 40px;

    @include respond-to(sm){
      right:calc(26vw - 40px);
      bottom:10vh;
      top: 30%;
      animation: frombottom .8s linear;
    }
    &:after{
      content:"";
      position:absolute;
      bottom:18px;
      display: block;
      width: 30px;
      height: 2px;
      background: $white;
      transform: rotate(-45deg);
    }
    &:before{
      content:"";
      position:absolute;
      bottom:18px;
      display: block;
      width: 30px;
      height: 2px;
      background: $white;
      transform: rotate(45deg);
    }
  }
  .prev-arrow-speciale{
    display:inline-block;
    position:absolute;
    top:calc(30% + 20px);
    left:calc(10% + 10px );
    z-index:9;
    font-size: 0;
    height: 40px;
    cursor:pointer;
    @include respond-to(sm){
      transition: transform .2s ;
      bottom:8%;
      animation: frombottom .8s linear;
      &:hover{
        transform: translateX(-10px);
        transition: transform .2s ;
      }
    }
    &:before{
      content:"";
      display:block;
      width:80px;
      height:2px;
      background: $white;
    }
    &:after{
      content:"";
      position:absolute;
      left: 0;
      top:-7px;
      width:15px;
      height: 15px;
      display:block;
      border-top: 2px solid $white;
      border-left: 2px solid $white;
      transform: rotate(-45deg);
    }
  }
  .next-arrow-speciale{
    display:inline-block;
    position:absolute;
    top:calc(30% + 20px);
    z-index:9;
    font-size: 0;
    height: 40px;
    right: calc(10% + 20px );
    cursor:pointer;
    @include respond-to(sm){
      transition: transform .2s ;
      bottom:8%;
      animation: frombottom .8s linear;
      &:hover{
        transform: translateX(10px);
        transition: transform .2s ;
      }
    }
    &:before{
      content:"";
      display:block;
      width:80px;
      height:2px;
      background: $white;
    }
    &:after{
      content:"";
      position:absolute;
      right: 0;
      top:-7px;
      width:15px;
      height: 15px;
      display:block;
      border-bottom: 2px solid $white;
      border-right: 2px solid $white;
      transform: rotate(-45deg);
    }
  }
  .slideInLeft{
    .right-side{
      @include respond-to(sm){
        animation:fromtop .5s linear;

      }

      .text-inside{

      }
    }
    .left-side{
      @include respond-to(sm){
        animation:frombottom .5s linear;

      }
      img{

      }
    }
  }
  .slideIn{
    .right-side{

      .text-inside{
        @include respond-to(sm){
        animation: frombottom .5s linear;
        }
      }
      .speciale{
        @include respond-to(sm){
          animation: frombottom .5s linear;
          // color:$blue;
        }
      }
    }
    .left-side{
      .immagine{
        @include respond-to(sm){
          animation: fromtop .5s linear;
        }
      }
    }
  }
  .slideOut{
    .right-side{
      .text-inside{
        @include respond-to(sm){
          animation: totop .5s linear;
        }
      }
      .speciale{
        @include respond-to(sm){
          animation: totop.5s linear;
        }
      }
    }
    .left-side{
      .immagine{
        @include respond-to(sm){
          animation: tobottom .5s linear;
        }
      }
    }
  }
  .slideOutRev{
    .right-side{
      .text-inside{
        @include respond-to(sm){
          animation: tobottom .5s linear;
        }
      }
      .speciale{
        @include respond-to(sm){
          animation: tobottom .5s linear;
        }
      }
    }
    .left-side{
      .immagine{
        @include respond-to(sm){
          animation: totop .5s linear;
        }
      }
    }
  }
}
