main{
  ::-moz-selection { /* Code for Firefox */
  color: $white;
  background: $blue;
}

::selection {
  color: $white;
  background: $blue;
}

  &.navopen{
z-index: 9998;
    overflow-y: hidden;
  }
}

// animation
.animatable, .anim_element {
opacity: 0;

transform: translateY(10rem) translateZ(0);
transition: opacity .6s ease-out, transform .8s cubic-bezier(0.165, 0.84, 0.44, 1);
will-change: transform;

&.animated,
&.soluzioni_list,
&.formazione_block,
&.about_w_decor_img,
&.anim_parallax_el {
  opacity: 1;
  transform: translateY(0);
}

}
.section.about_mod,
.section.soc_mod,
.about_w_decor_img,
.soc_w_decor_img{
pointer-events: none;
}

.anim_title {
transform: translateY(0);
opacity: 0;

div {
  opacity: 0;
}

&.animated {
  opacity: 1;
}

}
