.cards{
	padding: 120px 0;
	&:before{
		content: '';
		position: absolute;
		top: calc(50% - 50px);
		height: 100px;
		left: 0;
		right: 0;
		background: repeating-linear-gradient(
		  -45deg,
		  $white,
		  $white 5px,
		  $blue 5px,
		  $blue 10px
		);
	}
	.cards-slider{
		.slick-slide{
			padding: 0 10px 0;
			@include respond-to(md){
				padding: 0 20px 0;
			}
		}
		.single-card{
			background: #FFFFFF;
			box-shadow: 0 2px 4px 0 rgba(60,64,67,0.08), 0 6px 10px 4px rgba(60,64,67,0.15);
			text-align: center;
			margin: 0 auto;
			position: relative;
			@include respond-to(md){
				margin: 30px 15px;
			}
			.single-card-in{
				padding: 30px 25px;
				height: 411px;
				@include respond-to(md){
					height: auto;
					padding: 50px 25px;
				}
				span{
					&.number{
						font-family: $abril;
						font-size: 144px;
						position: absolute;
						left: 0;
						right: 0;
						z-index: 0;
					}
					&.yellow{
						color: $yellow
					}
					&.green{
						color: $green;
					}
					&.lilla{
						color: $lilla;
					}
				}
				p{
					font-size: 20px;
					@include mainfontbold();
					position: relative;
					z-index: 2;
				}
				h2{
					@include mainfont();
					font-weight: normal;
					font-size: 20px;
					margin-top: 75px;
					position: relative;
					z-index: 2;
					min-height: 55px;
					@include respond-to(md){
						min-height: auto
					}
					strong{
						@include mainfontbold();
					}
				}
				h3{
					margin-top: 40px;
					font-size: 28px;
					position: relative;
					z-index: 2;
					text-transform: uppercase;
					min-height: 106px;
					@include respond-to(md){
						min-height: auto;
						margin-top: 70px;
					}
					span{
						display: block;
						font-size: 20px;
						@include secondfontbold();
					}
				}
				h4{
					@include secondfontbold();
					font-size: 20px;
					position: relative;
					z-index: 2;
					display: inline-block;
					margin-top: 10px;
					@include respond-to(md){
						margin-top: 30px;
					}

					&:before{
						content: '';
						height: 2px;
						left: -10px;
						right: -10px;
						top: calc(50% - 1px);
						position: absolute;
						z-index: -1;
						min-width: 180px;
					}
					&.yellow{
						&:before{
							background: $yellow;
						}
					}
					&.green{
						&:before{
							background: $green;
						}
					}
					&.lilla{
						&:before{
							background: $lilla;
							left:-50%;
						}
					}
				}
				.angle-tl,
				.angle-tr,
				.angle-bl,
				.angle-br{
					position: absolute;
					width: 50px;
					height: 50px;
				}
				.angle-tl{
					left: 0;
					//top:30px;
					top:0;
					border-left: 10px solid $blue;
					border-top: 10px solid $blue;
				}
				.angle-tr{
					right: 0;
					//top:30px;
					top:0;
					border-right: 10px solid $blue;
					border-top: 10px solid $blue;
				}
				.angle-bl{
					left: 0;
					//bottom: 30px;
					bottom: 0;
					border-left: 10px solid $blue;
					border-bottom: 10px solid $blue;
				}
				.angle-br{
					right: 0;
					//bottom: 30px;
					bottom: 0;
					border-right: 10px solid $blue;
					border-bottom: 10px solid $blue;
				}
			}
		}
	}
}
