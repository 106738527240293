.tradizione{
	padding: 120px 0;
	.intro-area{
		text-align: center;
		margin-bottom: 40px;
		span{
			display: block;
			font-size: 14px;
			@include mainfontbold()
			@include respond-to(md) {
				display: none;
			}
		}
		h1{
			@include secondfontbold();
			color: $blue;
			font-size: 45px;
			line-height: 1.1;
			margin-bottom: 20px;
			@include respond-to(sm) {
				font-size: 60px;
			}
			@include respond-to(md) {
				font-size: 80px;
			}
			strong{
				color: $yellow;
			}
		}
		h2{
			display: none;
			@include respond-to(sm) {
				display: block;
				font-size: 18px;
				@include mainfontbold();
				width: 70%;
				margin: 0 auto;
				display: block;
				color: $blue;
			}
		}
	}
	.text-area{
		width: 100%;
		@include respond-to(md){
			width:90%;
		}
		p{
			font-size: 18px;
			margin-bottom: 20px;
		}
	}
	.gallery-area{
		.slick-gallery-tradizione{
			width: 100%;
			display: block;
			.slick-arrow{
				position: absolute;
				bottom: -40px;
				right: -40px;
				width: 95px;
				height: 20px;
				overflow: hidden;
				text-indent: -9999px;
				&:after{
					content: '';
					position: absolute;
					width: 10px;
					height: 10px;
					top: 5.5px;
				}
				&:before{
					content: '';
					position: absolute;
					top: 10px;
					height: 2px;
					width: 95px;
					background: $blue;
				}
				&.slick-next{
					transition: transform 0.25s;
					left: 55%;
					@include respond-to(md){
						left:auto;
						&:hover{
							transform: translateX(10px);
							transition: transform 0.25s;
							cursor: pointer;
						}
					}
					&:before{
						right: 2px;
					}
					&:after{
						border-bottom: 2px solid $blue;
						border-right: 2px solid $blue;
						transform: rotate(-45deg);
						right: 2px;
					}
				}
				&.slick-prev{
					right: 55%;
					transition: transform 0.25s;
					@include respond-to(md){
						right: 90px;
						&:hover{
							transform: translateX(-10px);
							transition: transform 0.25s;
							cursor: pointer;
						}
					}
					&:before{
						left: 2px;
					}
					&:after{
						border-bottom: 2px solid $blue;
						border-left: 2px solid $blue;
						transform: rotate(45deg);
						left: 2px;
					}
				}
			}
			.slick-slide{
				padding: 30px;
				}
			.slide{
				box-shadow: 0 2px 8px 0 rgba(35,46,94,0.30);
				img{
					width: 100%;
					display: block;
				}
			}
		}
	}
}
