.pane{
	padding: 120px 0 50px;
	position: relative;
	background: rgba($grey, 0.3);
	margin-top: -180px;
	z-index: 1;
	.intro-area{
		text-align: center;
		margin-bottom: 40px;
		span{
			display: block;
			font-size: 14px;
			@include mainfontbold()
			@include respond-to(md) {
				display: none;
			}
		}
		h1{
			@include secondfontbold();
			color: $blue;
			font-size: 45px;
			line-height: 1.1;
			margin-bottom: 20px;
			@include respond-to(sm) {
				font-size: 60px;
			}
			@include respond-to(md) {
				font-size: 80px;
			}
			strong{
				color: $lilla;
			}
		}
		h2{
			display: block;
			font-size: 18px;
			@include mainfontbold();
			width: 70%;
			margin: 0 auto;
			display: block;
			color: $blue;
			text-transform: uppercase;
		}
	}
	.pane-slider{
		width: 80%;
		margin: 0 auto;
		@include respond-to(md){
			width: 100%;
		}
		.single-slide-pane{
			cursor: pointer;
			background: #FFFFFF;
			//box-shadow: 0 2px 4px 0 rgba(60,64,67,0.08), 0 6px 10px 4px rgba(60,64,67,0.15);
			margin: 10px;
				// width:auto;
	    min-height: 300px;
			vertical-align: top;
			border-radius: 3px;
			transition: box-shadow 0.2s ease-in-out;
			box-shadow: 0;
			 border: 8px solid $white;

			@include respond-to(md){

				width: calc(25% - 20px) !important;
				&:hover{
					transition: box-shadow 0.3s 0.2s ease-in-out;
					transform: scale(1.005);
					transition: transform 0.2s  ease-in-out;
					box-shadow: 0 2px 4px 0 rgba(60,64,67,0.08), 0 6px 10px 4px rgba(60,64,67,0.15);
					// transition-delay: 0.2s;

				}
			}
			img{
				display: block;
				width: 80%;
				margin: 0 auto;
				min-height: 200px;
			}
			span{
				@include mainfont();
				font-size: 15px;
				color: $blue;
				text-align: center;
				margin: 10px 0 0;
				display: block;
			}
			h2{
				font-size: 35px;
				@include secondfontbold();
				min-height: 65px;
				text-align: center;
				padding: 0 15px 15px 15px;
				line-height: 1;

				@include respond-to(md){
					font-size:25px;
				}

			}
			&.speciale{
			 border: 8px solid $green;
			 transform: translateX(-10px);
			 @include respond-to(md){
				  transform: none;
					width: calc(25% - 20px) !important;
					height: calc(100% - 10px);
			 }
			 &:hover{

				 @include respond-to(md){
					 transition: box-shadow 0.3s 0.2s ease-in-out;
					 transform: scale(1.005);
					 transition: transform 0.2s  ease-in-out;
					 box-shadow: 0 2px 4px 0 rgba(60,64,67,0.08), 0 6px 10px 4px rgba(60,64,67,0.15);
				}

			 }
			}
		}
		.slick-track{
			padding-bottom: 10px;
		}
		.slick-arrow{
			position: absolute;
			top: calc(50% - 11px);
			overflow: hidden;
			text-indent: -99999px;
			width: 22px;
			height: 22px;
			&:hover{
				cursor: pointer;
				transform: translate();
			}
			&.slick-prev{
				left: -30px;
				border-bottom: 2px solid $blue;
				border-left: 2px solid $blue;
				transform: translate(0px) rotate(45deg);
				transition:.3s;
				&:hover{
				transform: translate(-5px) rotate(45deg);
				}
			}
			&.slick-next{
				right: -30px;
				border-bottom: 2px solid $blue;
				border-right: 2px solid $blue;
				transform:translate(0px)rotate(-45deg);
				transition:.3s;
				&:hover{
					transform: translate(5px) rotate(-45deg);
				}
			}
		}
	}
	.slick-slide{
		div{
			div{
				vertical-align: top;
			}
		}
	}
}
