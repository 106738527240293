$line-height: 80px;
$line-height-abs: -80px;

@keyframes scrolldown {
	0%{
		background-position: 0 $line-height-abs;
	}
	75% {
		background-position: 0 0;
	}
	100%{
		background-position: 0 $line-height;
	}
}

.opening{
  @include colorclasses(background);
  .content-inner{
    width: 305px;
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;
		@include respond-to(md){
			width: 335px;
		}
    img{
      display: block;
      width: 50%;
			margin: 0 auto;
			@include respond-to(md){
				width: 100%;
			}
    }
    h1{
      @include mainfontbold();
      text-transform: uppercase;
      color: $blue;
      font-size: 17px;
      margin-top: 20px;
      letter-spacing: 0.45px;
      text-align: center;
			@include respond-to(md){
				font-size: 20px;
			}
    }
  }
  .scroll-down{
    position: absolute;
    bottom: 80px;
    left: calc(50% - 75px);
    color: $blue;
    width: 150px;
    .line{
      height: 80px;
      width: 1px;
      // background: $blue;
      background: linear-gradient(to bottom, rgba($blue, 1) 50%, rgba(255, 255, 255, 0) 50%);
			background-position: 0 $line-height-abs;
			background-size: 100% 200%;
			animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
      display: block;
      margin: 0 auto 10px;
    }
    .scroll-label{
      display: block;
      margin: 0 auto 30px;
      width: 150px;
      color: $blue;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      @include mainfont();
      text-transform: uppercase;
      letter-spacing: 5px;
    }
		@include respond-to(md){
			bottom:0;
		}
  }

	.opening-pani{
			position: relative;
			top:0;
			right:0;
			left:0;
			bottom:0;
		img{
			position: absolute;
			&.p-12{
				width: 70%;
		    top: 3vh;
		    right: 0;
		    left: 15%;
				z-index: -1;
				@include respond-to(md){
					width: 33%;
					top: 5px;
					right: 0;
					left: 33%;
					z-index: -1;
					transition: 0.6s ease-in-out;
					animation: opentop .6s;
					&.go-away{
						transform:translateY(-1000px);
						transition: 0.6s ease-in-out;
					}
				}
			}
			&.p-02{
				display: none;
				@include respond-to(md){
					display:block;
					width: 25%;
					top: 0;
					right: 0;
					z-index: -1;
					 transition: 0.6s ease-in-out;
					 animation: opentopdiag .6s;
					 &.go-away{
						 transform: translate(1000px, -1000px);
							transition: 0.6s ease-in-out;
					 }
				}
			}

			&.p-02-mobile{
				width: 30%;
				top: 20vh;
				right: 0;
				z-index: -1;
				display:block;
				@include respond-to(md){
					display: none;
				}
			}
			&.p-03{
				display:none;
				@include respond-to(md){
					display:block;
					width: 13%;
					right: 13%;
					top: 53vh;
					z-index: -2;
					transition: 0.6s ease-in-out;
					animation: openright .6s;
					&.go-away{
						transform: translateX(1000px);
						 transition: 0.6s ease-in-out;
					}
				}

			}
			&.p-04{
				width: 40%;
				top: 65vh;
				right: 0;
				z-index: -2;
				@include respond-to(md){
					display:block;
					width: 18%;
			    top: 59vh;
			    right: 0;
					z-index: -2;
					transition: 0.6s ease-in-out;
					animation: openrightdiag .6s;
					&.go-away{
						transform: translate(1000px, 1000px);
						 transition: 0.6s ease-in-out;
					}
				}

			}
			&.p-05{
				display:none;
				@include respond-to(md){
					display:block;
				width: 21%;
				top: 70vh;
				right: 22%;
				z-index: -3;
				transition: 0.6s ease-in-out;
				animation: openright .6s;
				&.go-away{
					transform: translateX(1000px);
					 transition: 0.6s ease-in-out;
				}
				}

			}
			&.p-07{
				display:none;
				@include respond-to(md){
					display:block;
				width: 20%;
				top: 73vh;
				left: 22%;
				z-index: -1;
				transition: 0.6s ease-in-out;
				animation: openleft .6s;
				&.go-away{
					transform: translateX(-1000px);
					 transition: 0.6s ease-in-out;
				}
				}

			}
			&.p-08{
				width: 50%;
				top: 62vh;
				left: -20px;
				z-index: -1;
				@include respond-to(md){
					width: 24%;
					top: 45vh;
					left: -5px;
					z-index: -1;
					transition: .6s ease-in-out;
					animation: openbottomdiag .6s;
					&.go-away{
						transform: translate(-1000px, 1000px);
						 transition: 0.6s ease-in-out;
					}
				}

			}
			&.p-09{
				width: 25%;
				top: 25vh;
				left: 37%;
				z-index: -1;

				@include respond-to(md){
					display:block;
					width: 13%;
					top: calc(32vh - 2px);
					left: 3%;
					z-index: -1;
					transition: .6s ease-in-out;
					animation: openleft .6s;
					&.go-away{
						transform: translateX(-1000px);
						 transition: 0.6s ease-in-out;
					}
				}
				@media screen and (max-height: 668px) {
					display:none;
				}

			}
			&.p-10{
				display: none;
				@include respond-to(md){
					display: block;
					width: 20%;
					top: 14vh;
					left: 17%;
					z-index: -3;
					transition: 0.6s ease-in-out;
					animation: openleftdiag .6s;
					&.go-away{
						transform: translate(-1000px, -1000px);
						 transition: 0.6s ease-in-out;
					}
				}

			}
			&.p-10-mobile{
				width: 42%;
				top: 12vh;
				left: 0;
				z-index: -1;
				@include respond-to(md){
					display:none;
				}
			}
			&.p-11{
				display:none;
				@include respond-to(md){
					display:block;
					width:20%;
					top:-30px;
					z-index: -3;
					transition: .6s ease-in-out;
					animation: openleftdiag .6s;
					&.go-away{
						transform: translateX(-1000px);
						 transition: 0.6s ease-in-out;
					 }
				}
				@include respond-to(xlg){
					width:15%;
					top:-80px;
				}
			}
		}
	}
}

@keyframes openleft {
  0% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes openleftdiag {
  0% {
    transform: translate(-1000px, -1000px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes openbottomdiag {
  0% {
    transform: translate(-1000px, 1000px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes openright {
  0% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes openrightdiag {
  0% {
    transform: translate(1000px, 1000px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes opentopdiag {
  0% {
    transform: translate(1000px, -1000px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes opentop{
  0% {
    transform:translateY(-1000px);
  }
  100% {
    transform: translateX(0);
  }
}
